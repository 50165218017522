import * as appconst from './service-constants';
import axios from 'axios';
import { IStudentsBySchoolResponseModel, IStudentsByGuardianResponseModel } from '../models/dto-models';



export class StudentService {

    public static GetStudentsBySchool = async (): Promise<IStudentsBySchoolResponseModel[]> => {
        const methname = `GetStudentsBySchool()`;
        console.log(`${methname} invoked.`);

        return new Promise<IStudentsBySchoolResponseModel[]>((resolve, reject) => {

            axios({
                method: 'GET',
                url: `${appconst.STUDENTS_GETSTUDENTSBYSCHOOLID_URL}`,
            }).then((res) => {
                console.log(`${methname} resolving with `, res.data);
                resolve(res.data);
            }).catch(err => {
                console.error(`${methname}`, err);
                // throw err;
                reject(err);
            });

        });
    }


    public static GetStudentsByGuardian = async (): Promise<IStudentsByGuardianResponseModel[]> => {
        const methname = `GetStudentsByGuardian()`;
        console.log(`${methname} invoked.`);

        return new Promise<IStudentsByGuardianResponseModel[]>((resolve, reject) => {

            axios({
                method: 'GET',
                url: `${appconst.STUDENTS_GETSTUDENTSBYGUARDIAN_URL}`,
            }).then((res) => {
                console.log(`${methname} resolving with `, res.data);
                resolve(res.data);
            }).catch(err => {
                console.error(`${methname}`, err);
                // throw err;
                reject(err);
            });

        });
    }


}