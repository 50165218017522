import React, { useEffect, useContext, useState } from "react";
import authStore from '../store/authstore/auth-store';
import { IAuthStoreDataModel } from "../models/store-models";


// create a context of type IAuthStoreDataModel whose initial state is null
const AuthContext  = React.createContext<{authData: IAuthStoreDataModel, setAuthData: React.Dispatch<React.SetStateAction<IAuthStoreDataModel>>}>(null);
AuthContext.displayName = 'AuthContext';


const AuthProvider = props => {

    const methname = `[AuthProvider] `;

    // let isLoggedIn = authStore.getIsLoggedIn();
    // let role = authStore.getUserRole();    
    const [authData, setAuthData] = useState<IAuthStoreDataModel>(authStore.getStoreData());


    useEffect(() => {
        function onAuthChange(){
            // isLoggedIn = authStore.getIsLoggedIn();
            // role = authStore.getUserRole();
            setAuthData(authStore.getStoreData());
            console.log(`[${methname}-useEffect-onAuthChange] returning `, authData);
        }
        
        authStore.addChangeListener(onAuthChange);
        return () => authStore.removeChangeListener(onAuthChange);
    }, []);

    return <AuthContext.Provider value={{authData, setAuthData}}>{props.children}</AuthContext.Provider>;

}


// custom hook for the AuthContext
export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(`useAuth must be used within a AuthProvider.`);
    }
    return context;
}


export default AuthProvider;
