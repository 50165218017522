

export const noRoutes = {  name: `Login`,  to: '/auth',  exact: true,  icon: 'chart-pie'  };




export default [
  noRoutes
];
