import React from 'react';
import PropTypes from 'prop-types';
import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownComponents from './NavbarDropdownComponents';
import {
  schoolRoutes,
  queueRoutes,
  staffRoutes,
  classRoomRoutes,
  studentRoutes,
  parentRoutes,
  reportRoutes,
  announcementRoutes,
  profileRoutes
} from '../../routes';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { breakpoints } from '../../helpers/utils';
import { topNavbarBreakpoint } from '../../config';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed }) => {
  //const components = [componentRoutes, pluginRoutes, utilityRoutes];
  const components = [schoolRoutes, staffRoutes, classRoomRoutes];

  //const pages = [pageRoutes, kanbanRoutes, widgetsRoutes, chatRoutes, emailRoutes, ECommerceRoutes];
  const pages = [schoolRoutes, queueRoutes, staffRoutes, classRoomRoutes, studentRoutes, parentRoutes, reportRoutes, announcementRoutes, profileRoutes];
  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
  };

  return (
    <>
      <NavbarDropdown
        title={schoolRoutes.name}
        items={schoolRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown title={schoolRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdownComponents
        title={schoolRoutes.name}
        items={components}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={schoolRoutes.name}
        items={pages}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />

      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to="/documentation">
          Documentation
        </NavLink>
      </NavItem>
    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
