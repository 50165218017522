
export class PersistDataServiceKeyNames {
    private static prefix = `SQ`;
    public static SQAuthStore = `${PersistDataServiceKeyNames.prefix}.AuthStore`;
    public static SQUserStore = `${PersistDataServiceKeyNames.prefix}.UserStore`;
}

class PersistDataService {

    private static cname = `[PersistDataService] `;

    static keys: string[] = [];

    static retreive = (value: string) => {
        return sessionStorage.getItem(value);
    }

    static store = (key: string, value: string) => {
        PersistDataService.keys.push(value);
        sessionStorage.setItem(key, value);
    }

    static clear = (key: string) => {
        sessionStorage.removeItem(key);        
    }

    static clearAll = () => {
        sessionStorage.clear();
        return new Promise((res, rej)=> {
            res(true);
        });
    }
}

export default PersistDataService;