import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Nav, Navbar } from 'reactstrap';
import is from 'is_js';
import classNames from 'classnames';
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import AppContext from '../../context/Context';
import Flex from '../common/Flex';

import { navbarBreakPoint } from '../../config';

import { AppRolesEnum } from '../../constants/AppRolesEnum';

import bgNavbarImg from '../../assets/img/generic/bg-navbar.png';
import { useAuth } from '../../providers/auth-provider';
import { useUser } from '../../providers/user-provider';

import noneLinks from '../../links/noneLinks';
import adminLinks from '../../links/adminLinks';
import staffLinks from '../../links/staffLinks';
import guardianLinks from '../../links/guardianLinks';
import authStore from '../../store/authstore/auth-store';








const NavbarVertical = ({ isKanban, navbarStyle }) => {

  const methname = `[NavbarVertical] `;

  const navBarRef = useRef(null);

  const { showBurgerMenu, isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed } = useContext(AppContext);

  const { role } =  useAuth();
  const { userData } = useUser();


  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical navbar-glass navbar-left-ontop navbar-sq-back', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      light
    >
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" width={40} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
        style={
          navbarStyle === 'vibrant' && {
            // backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`
            //backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.96), #FA6400),url(${bgNavbarImg})`
            //backgroundImage: `radial-gradient(circle at 92% 37%, hsla(94,0%,12%,0.07) 0%, hsla(94,0%,12%,0.07) 39%,transparent 39%, transparent 71%,transparent 71%, transparent 100%),radial-gradient(circle at 65% 60%, hsla(94,0%,12%,0.07) 0%, hsla(94,0%,12%,0.07) 15%,transparent 15%, transparent 67%,transparent 67%, transparent 100%),radial-gradient(circle at 18% 57%, hsla(94,0%,12%,0.07) 0%, hsla(94,0%,12%,0.07) 23%,transparent 23%, transparent 31%,transparent 31%, transparent 100%),radial-gradient(circle at 38% 0%, hsla(94,0%,12%,0.07) 0%, hsla(94,0%,12%,0.07) 27%,transparent 27%, transparent 60%,transparent 60%, transparent 100%),radial-gradient(circle at 83% 39%, hsla(94,0%,12%,0.07) 0%, hsla(94,0%,12%,0.07) 74%,transparent 74%, transparent 87%,transparent 87%, transparent 100%),linear-gradient(135deg, rgb(243, 77, 35),rgb(95,142,69))`
            //backgroundImage: `radial-gradient(circle at center center, rgb(241, 195, 19),rgb(244, 150, 32),rgb(246, 105, 45),rgb(249, 59, 57),rgb(251, 14, 70))`
            backgroundImage: `radial-gradient(circle at 46% 12%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 3%,transparent 3%, transparent 100%),radial-gradient(circle at 43% 86%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 3%,transparent 3%, transparent 100%),radial-gradient(circle at 36% 69%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 3%,transparent 3%, transparent 100%),radial-gradient(circle at 74% 62%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 7%,transparent 7%, transparent 100%),radial-gradient(circle at 11% 65%, rgba(255,255,255,0.01) 0%, rgba(255,255,255,0.01) 7%,transparent 7%, transparent 100%),radial-gradient(circle at 44% 92%, rgba(255,255,255,0.02) 0%, rgba(255,255,255,0.02) 7%,transparent 7%, transparent 100%),radial-gradient(circle at 68% 61%, rgba(255,255,255,0.02) 0%, rgba(255,255,255,0.02) 7%,transparent 7%, transparent 100%),radial-gradient(circle at 88% 9%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 7%,transparent 7%, transparent 100%),radial-gradient(circle at 59% 86%, rgba(255,255,255,0.02) 0%, rgba(255,255,255,0.02) 7%,transparent 7%, transparent 100%),radial-gradient(circle at 91% 23%, rgba(255,255,255,0.02) 0%, rgba(255,255,255,0.02) 5%,transparent 5%, transparent 100%),radial-gradient(circle at 67% 78%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 5%,transparent 5%, transparent 100%),radial-gradient(circle at 0% 17%, rgba(255,255,255,0.02) 0%, rgba(255,255,255,0.02) 5%,transparent 5%, transparent 100%),radial-gradient(circle at 93% 12%, rgba(255,255,255,0.02) 0%, rgba(255,255,255,0.02) 5%,transparent 5%, transparent 100%),radial-gradient(circle at 93% 94%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 5%,transparent 5%, transparent 100%),radial-gradient(circle at 90% 17%, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 5%,transparent 5%, transparent 100%),linear-gradient(282deg, rgb(189,94,21),rgb(218, 103, 37))`

          }
        }
      >
        <Nav navbar vertical>          
          {/* <NavbarVerticalMenu routes={routes} /> */}
          {getNavBarVerticalMenu()}
        </Nav>
        
      </Collapse>
    </Navbar>
  );
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
  navbarStyle: 'transparent'
};


const getNavBarVerticalMenu = (schoolName) => {
  let role = authStore.getUserRole();
  switch(role) {
    case AppRolesEnum.SchoolAdmin:
      adminLinks[0].name = authStore.getSchoolName();   // 0th position is assumed to be the school name route.
      return (
          <NavbarVerticalMenu routes={adminLinks} />
      )
    case AppRolesEnum.SchoolStaff:
      staffLinks[0].name = authStore.getSchoolName();   // 0th position is assumed to be the school name route.
      return (
        <NavbarVerticalMenu routes={staffLinks} />
      )
    case AppRolesEnum.Guardian:
      return (
        <NavbarVerticalMenu routes={guardianLinks} />
      )
    default:
      return (
        <NavbarVerticalMenu routes={noneLinks} />
      )
  }
}




export default NavbarVertical;
