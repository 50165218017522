import * as serviceConst from './services/service-constants';
import * as appConst from './constants/app-constants';
import { HubConnectionBuilder, LogLevel, HubConnection } from '@aspnet/signalr';
import authStore from './store/authstore/auth-store';


export interface SignalrProps {
    service: SignalrService,
}

class SignalrService {

    private readonly cname: string = `[SignalrService]`;
    private connection: HubConnection | undefined = undefined;

    private registrations: {component, fnName}[] = [];

    constructor() {
        
    }


    public init = (): HubConnection | undefined => {
        this.connection = new HubConnectionBuilder()
            .withUrl(`${serviceConst.SCHOOL_HUB_URL}`, {
                accessTokenFactory: () => authStore.getAuthToken()
            })
            .configureLogging(LogLevel.Debug)
            .build();


        this.whenSayHello(`${this.cname}`, (msg: string) => {
            console.log(`${this.cname} ${appConst.SignalrClientFunctions.SayHello} from server: `, msg);
        });

        this.whenReceiveStudentMessage(`${this.cname}`, (msg: string, cid: string) => {
            console.log(`${this.cname} ${appConst.SignalrClientFunctions.ReceiveQueueMessage} from server: `, msg, cid);
        });

        // TODO: TEMPORARY until server side refactor to remove calls to server functions and injecting SchoolQHub as a service.
        this.whenApiActivity(`${this.cname}`, (msg: string, cid: string) => {
            console.log(`${this.cname} ApiActivity from server: `, msg, cid);
        });



        this.connection.onclose((err: any) => {
            console.log(`${this.cname} Signalr hub connection closed.`, err);
            this.reConnect();
        });

        this.connection.start().then(() => {
            console.log(`${this.cname} Signalr connection started`);
        }).catch((err: any) => {
            console.error(`${this.cname} Error.`, err);
            return undefined;
        });

        return this.connection;
    }


    private isRegistered = (component: string, fnName: string) : boolean => {
        let isRegistered = this.registrations.findIndex((value, index, arr) => {
            if (value.component === component && value.fnName === fnName) return true;
        });
        return isRegistered > 0 ? true: false;
    }


    // Client side signalr functions
    // allow registration with the when* function calls
    public whenSayHello = (component: string, callback: (msg: string) => void): boolean => {
        let fnWhen = `whenSayHello`;
        if (!this.isRegistered(component, fnWhen)) {
            if (this.connection !== undefined) {
                this.registrations.push({component: component, fnName: fnWhen});        // save the callback registration in the list to weed out duplicates
                this.connection.on(appConst.SignalrClientFunctions.SayHello, (msg: string) => {
                    callback(msg);
                });
                return true;
            }
        }
        return false;
    }

    public whenReceiveStudentMessage = (component: string, callback: (message: string, classRoomIdentifier: string) => void): boolean => {
        let fnWhen = `whenReceiveStudentMessage`;
        if (!this.isRegistered(component, fnWhen)) {
            if (this.connection !== undefined) {
                this.registrations.push({component: component, fnName: fnWhen});
                this.connection.on(appConst.SignalrClientFunctions.ReceiveStudentMessage, (msg: string, cid: string) => {
                    callback(msg, cid);
                });
                return true;
            }
        }
        return false;
    }


    // TODO: Temp until server side refactor
    public whenApiActivity = (component: string, callback: (message: string, classRoomIdentifier: string) => void): boolean => {
        let fnWhen = `whenApiActivity`;
        if (!this.isRegistered(component, fnWhen)) {
            if (this.connection !== undefined) {
                this.registrations.push({component: component, fnName: fnWhen});
                this.connection.on('ApiActivity', (msg: string, cid: string) => {
                    callback(msg, cid);
                });
                return true;
            }
        }
        return false;
    }

    
    // TODO: Temp until server side refactor
    public whenStudentDroppedOff = (component: string, callback: (message: string, classRoomIdentifier: string) => void): boolean => {
        let fnWhen = `whenStudentDroppedOff`;
        if (!this.isRegistered(component, fnWhen)) {
            if (this.connection !== undefined) {
                this.registrations.push({component: component, fnName: fnWhen});
                this.connection.on('StudentDroppedOff', (msg: string, cid: string) => {
                    callback(msg, cid);
                });
                return true;
            }
        }
        return false;
    }

    // TODO: Temp until server side refactor
    public whenStudentPickedUp = (component: string, callback: (message: string, classRoomIdentifier: string) => void): boolean => {
        let fnWhen = `whenStudentPickedUp`;
        if (!this.isRegistered(component, fnWhen)) {
            if (this.connection !== undefined) {
                this.registrations.push({component: component, fnName: fnWhen});
                this.connection.on('StudentPickedUp', (msg: string, cid: string) => {
                    callback(msg, cid);
                });
                return true;
            }
        }
        return false;
    }




    // invoke server side functions
    public invokeSomeServerMethodSayHello = (msg: string): Promise<any> | undefined => {
        return this.connection ?
            this.connection.invoke(appConst.SignalrServerFunctions.SomeServerMethodSayHello, msg) :
            undefined;
    }

    public invokeBroadcastStudentMessage = (msg: string, classRoomIdentifier: string): Promise<any> | undefined => {
        return this.connection ?
            this.connection.invoke(appConst.SignalrServerFunctions.BroadcastStudentMessage, msg, classRoomIdentifier) :
            undefined;
    }




    // TODO: needs testing. also add exponential backoff functionality
    private reConnect = () => {

        const clearConnect = setTimeout(() => {
            if (this.connection !== undefined) {
                console.log(`${this.cname} Trying to re-start connection...`);
                this.connection.start().then(() => {
                    clearTimeout(clearConnect)
                    console.log(`${this.cname} Reconnected. setTimeout cancelled.`);
                }).catch((err: any) => {
                    console.log(`${this.cname} connection restart error`, err);
                });
            }
            else {
                console.log(`${this.cname} connection is undefined.`);
            }

        }, 1000);

    }
}

export default SignalrService;