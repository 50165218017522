import React, { useEffect, useContext, useState } from "react";
import userStore from '../store/userstore/user-store';
import { IUserStoreDataModel } from "../models/store-models";


// create a context whose initial state is null
const UserContext  = React.createContext<{userData: IUserStoreDataModel, setUserData: React.Dispatch<React.SetStateAction<IUserStoreDataModel>>}>(null);


const UserProvider = props => {

    const methname = `[UserProvider] `;

    const [userData, setUserData] = useState<IUserStoreDataModel>(userStore.getStoreData());

    useEffect(() => {
        function onUserChange(){            
            setUserData(userStore.getStoreData());
            console.log(`[${methname}-useEffect-onUserChange] returning `, userData);
        }
        
        userStore.addChangeListener(onUserChange);
        return () => userStore.removeChangeListener(onUserChange);
    }, []);

    return <UserContext.Provider value={{userData, setUserData}}>{props.children}</UserContext.Provider>;
}


// custom hook for the AuthContext
export function useUser() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error(`useUser must be used within a UserProvider.`);
    }
    return context;
}


export default UserProvider;
