export enum SignalrServerFunctions {
    SomeServerMethodSayHello = 'SomeServerMethodSayHello',
    BroadcastStudentMessage = 'BroadcastStudentMessage'
}

export enum SignalrClientFunctions {
    SayHello = 'SayHello',
    ReceiveQueueMessage = 'ReceiveQueueMessage',
    ReceiveStudentMessage = 'ReceiveStudentMessage',
}
