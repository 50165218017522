import * as appconst from './service-constants';
import axios from 'axios';
import { IGuardiansBySchoolResponseModel } from '../models/dto-models';
import authStore from '../store/authstore/auth-store';



export class GuardianService {

    public static GetGuardiansBySchool = async (): Promise<IGuardiansBySchoolResponseModel[]> => {
        const methname = `GetGuardiansBySchool()`;
        console.log(`${methname} invoked.`);

        return new Promise<IGuardiansBySchoolResponseModel[]>((resolve, reject) => {

            axios({
                method: 'GET',
                url: `${appconst.GUARDIAN_GETGUARDIAN_BYSCHOOL_URL}`,
            }).then((res) => {
                console.log(`${methname} resolving with `, res.data);
                resolve(res.data);
            }).catch(err => {
                console.error(`${methname}`, err);
                // throw err;
                reject(err);
            });

        });
    }
}