

export const schoolRoutes = {  name: `My School`,  to: '/staff',  exact: true,  icon: 'chart-pie'  };
export const queueRoutes = {  name: 'Queue',  to: '/staff/queue',  icon: 'lock'  };
export const classRoomRoutes = {  name: 'Class Rooms',  to: '/staff/classroom',  icon: 'cart-plus'};
export const studentRoutes = {  name: 'Students',  to: '/staff/students',  icon: 'copy'};
export const reportRoutes = {  name: 'Reports',  to: '/staff/reports',  exact: true,  icon: 'comments'};
export const announcementRoutes = {  name: 'Announcements',  to: '/staff/announcements',  exact: true,  icon: ['fab', 'trello']};
export const profileRoutes = {  name: 'Profile',  to: '/staff/profile',  icon: 'envelope-open'};



export default [
  schoolRoutes,
  queueRoutes,
  classRoomRoutes,
  studentRoutes,
  reportRoutes,
  announcementRoutes,
  profileRoutes
];
