import * as appconst from './service-constants';
import axios from 'axios';
import { ILoginInputModel, ILoginResponseModel } from '../models/dto-models';


export class AuthService {

    public static Login = async (login: ILoginInputModel): Promise<ILoginResponseModel> => {
        const methname = `Login(${login.username},${login.password})`;
        console.log(`${methname} invoked.`);

        return new Promise<ILoginResponseModel>((resolve, reject) => {

            axios({
                method: 'POST',
                url: `${appconst.LOGIN_URL}`,
                data: login
            }).then((res) => {
                resolve(res.data);
            }).catch(err => {
                console.error(`${methname}`, err);
                // throw err;
                reject(err);
            });

        });
    }
}