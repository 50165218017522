

export const schoolRoutes = {  name: `My School`,  to: '/admin',  exact: true,  icon: 'chart-pie'  };
export const queueRoutes = {  name: 'Queue',  to: '/admin/queue',  icon: 'lock'  };
export const staffRoutes = {  name: 'School Staff',  to: '/admin/staff',  icon: 'lock'};
export const classRoomRoutes = {  name: 'Class Rooms',  to: '/admin/classroom',  icon: 'cart-plus'};
export const studentRoutes = {  name: 'Students',  to: '/admin/students',  icon: 'copy'};
export const parentRoutes = {  name: 'Parents',  to: '/admin/parents',  exact: true,  icon: 'poll'};
export const reportRoutes = {  name: 'Reports',  to: '/admin/reports',  exact: true,  icon: 'copy'};
export const announcementRoutes = {  name: 'Announcements',  to: '/admin/announcements',  exact: true,  icon: ['fab', 'trello']};
export const profileRoutes = {  name: 'Profile',  to: '/admin/profile',  icon: 'envelope-open'};



export default [
  schoolRoutes,
  queueRoutes,
  staffRoutes,
  classRoomRoutes,
  studentRoutes,
  parentRoutes,
  reportRoutes,
  announcementRoutes,
  profileRoutes
];
