import React, { useEffect, useContext, useState, Dispatch, SetStateAction } from "react";
import { IQueueMessage } from "../components/queue/current-queue";
import SignalrService from "../signalr-system";
import authStore from "../store/authstore/auth-store";


// create a context whose initial state is null
// https://stackoverflow.com/questions/54577865/react-createcontext-issue-in-typescript
// setMessages: ({type}:{type:string}) => void
const SignalrContext  = React.createContext<{
    signalr: SignalrService, 
    messages: {data: IQueueMessage[]}, 
    setMessages: Dispatch<SetStateAction<{ data: IQueueMessage[]; }>> 
}>(null);


const SignalrProvider = props => {

    const methname = `[SignalrProvider] `;

    const [isLoggedIn, ] = useState(authStore.getIsLoggedIn());

    const [signalr, setSignalr] = useState<SignalrService>(null);
    const [messages, setMessages] = useState<{data: IQueueMessage[]}>();

    useEffect(() => {
        console.log(`${methname} Instantiate a new SignalrProvider. isLoggedIn: ${isLoggedIn}`);
        let sr = new SignalrService();
        setSignalr(sr);
        setMessages({data: [{id: 1, message: 'Welcome', classRoomId: '---'}]});

        function onAuthChange(){
            var isLoggedIn = authStore.getIsLoggedIn();
            if (isLoggedIn) {
                console.log(`${methname} isLoggedIn: '${isLoggedIn}'. Init signalrConnection`);
                sr.init();
            }
            console.log(`[${methname}-useEffect-signalrProvider] returning `, isLoggedIn);
        }
        
        authStore.addChangeListener(onAuthChange);
        return () => authStore.removeChangeListener(onAuthChange);
    }, []);

    return <SignalrContext.Provider value={{signalr, messages, setMessages}}>{props.children}</SignalrContext.Provider>;
}


// custom hook for the AuthContext
export function useSignalr() {
    const context = useContext(SignalrContext);
    if (!context) {
        throw new Error(`useSignalr must be used within a SignalrProvider.`);
    }
    return context;
}


export default SignalrProvider;
