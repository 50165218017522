import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import AuthProvider from './providers/auth-provider';
import './helpers/initFA';
import UserProvider from './providers/user-provider';
import SignalrProvider from './providers/signalr-provider';

ReactDOM.render(
  <AuthProvider>
    <UserProvider>
      <SignalrProvider>
        <Main>
          <App/>
        </Main>
      </SignalrProvider>
    </UserProvider>
  </AuthProvider>,
  document.getElementById('main')
);
