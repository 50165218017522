import { EventEmitter } from "events";
import appDispatcher from "../app-dispatcher";
import { IUserStoreDataModel } from "../../models/store-models";
import PersistDataService, { PersistDataServiceKeyNames } from "../../services/local-storage";


const USER_CHANGE = 'change';


export const UserActionTypes = {
    HYDRATE_IN_MEM_USER_STORE: 'HYDRATE_IN_MEM_USER_STORE',
    STAFF_BY_SCHOOL: 'STAFF_BY_SCHOOL',
    STUDENT_BY_SCHOOL: 'STUDENT_BY_SCHOOL',
    STUDENTS_BY_GUARDIAN: 'STUDENTS_BY_GUARDIAN',
    GUARDIANS_BY_SCHOOL: 'GUARDIANS_BY_SCHOOL',
    SCHOOL_INFO: 'SCHOOL_INFO',
    CLASSROOM_INFO: 'CLASSROOM_INFO',
    LOGGED_OUT: 'LOGGED_OUT'
}



// ========================================
// Create an instance of the primary UserStoreData
// Keep it private without exposing it outside
// so that it is not manipulated directly.
// It has to be manipulated via the exposed
// UserStore instance only.
// ========================================
const _userStoreData: IUserStoreDataModel = {
    user: {
        userName: '',
        userDisplayName: '',
        schoolDisplayNames:[]        
    },
    staff: [],
    classrooms: [],
    students: [],
    guardians: [],
    school: {
        activeSeasonDescription: '',
        activeSeasonName: '',
        contactPersonName: '',
        displayName: '',
        id: -1,
        phone: '',
        schoolAddress: '',
        schoolName: '',
        activeSeasonId: -1
    }
};
// ========================================



class UserStore extends EventEmitter {

    addChangeListener(callback: any) {
        this.on(USER_CHANGE, callback);
    }

    removeChangeListener(callback: any) {
        this.removeListener(USER_CHANGE, callback);
    }

    emitChange() {
        console.log(`================`);
        console.log(`PERSISTING DATA: `, _userStoreData);
        console.log(`================`);
        PersistDataService.store(PersistDataServiceKeyNames.SQUserStore, JSON.stringify(_userStoreData));
        this.emit(USER_CHANGE);
    }

    getSchoolName(): string {
        console.log(`--------------> Vamsi `, _userStoreData?.school?.displayName);
        return _userStoreData?.school?.displayName;
    }

    getStoreData(): IUserStoreDataModel {
        return _userStoreData;
    }
}





// create an instance of the authstore
const userStore = new UserStore();

// register reducer actions with the dispatcher
appDispatcher.register((action: any) => {

    const mname = `[UserStore.appDispatcher.register] `;
    switch (action.type) {

        case UserActionTypes.HYDRATE_IN_MEM_USER_STORE:
            let hydratedUserStr = PersistDataService.retreive(PersistDataServiceKeyNames.SQUserStore);
            let hydratedUser: IUserStoreDataModel = JSON.parse(hydratedUserStr);

            _userStoreData.user = hydratedUser?.user;
            _userStoreData.staff = hydratedUser?.staff;
            _userStoreData.guardians = hydratedUser?.guardians;
            _userStoreData.school = hydratedUser?.school;
            _userStoreData.students = hydratedUser?.students;
            _userStoreData.classrooms = hydratedUser?.classrooms;

            userStore.emitChange();
            break;

        case UserActionTypes.STAFF_BY_SCHOOL:
            console.log(`${mname} invoked with ${UserActionTypes.STAFF_BY_SCHOOL}`, action.payload);

            _userStoreData.staff = action.payload;

            userStore.emitChange();
            break;

        case UserActionTypes.STUDENT_BY_SCHOOL:
            console.log(`${mname} invoked with ${UserActionTypes.STUDENT_BY_SCHOOL}`, action.payload);

            _userStoreData.students = action.payload;

            userStore.emitChange();
            break;

        case UserActionTypes.STUDENTS_BY_GUARDIAN:
            console.log(`${mname} invoked with ${UserActionTypes.STUDENTS_BY_GUARDIAN}`, action.payload);

            _userStoreData.students = action.payload;

            userStore.emitChange();
            break;
            
        case UserActionTypes.GUARDIANS_BY_SCHOOL:
            console.log(`${mname} invoked with ${UserActionTypes.GUARDIANS_BY_SCHOOL}`, action.payload);

            _userStoreData.guardians = action.payload;

            userStore.emitChange();
            break;

        case UserActionTypes.SCHOOL_INFO:
            console.log(`${mname} invoked with ${UserActionTypes.SCHOOL_INFO}`, action.payload);

            _userStoreData.school = action.payload;

            userStore.emitChange();
            break;
            
        case UserActionTypes.CLASSROOM_INFO:
            console.log(`${mname} invoked with ${UserActionTypes.CLASSROOM_INFO}`, action.payload);

            _userStoreData.classrooms = action.payload;

            userStore.emitChange();
            break;            

        case UserActionTypes.LOGGED_OUT:
            console.log(`${mname} invoked with ${UserActionTypes.LOGGED_OUT}`);
            
            _userStoreData.guardians = [];
            _userStoreData.school = null;
            _userStoreData.staff = [];
            _userStoreData.students = [];
            _userStoreData.user = null;
            _userStoreData.classrooms = [];

            userStore.emitChange();
            PersistDataService.clear(PersistDataServiceKeyNames.SQUserStore);
            break;            

        default:
            console.log(`${mname} invalid action type`, action.type);
            break;
    }
});

export default userStore;