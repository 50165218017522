

export const myKidsRoutes = {  name: `My Kids`,  to: '/guardian',  exact: true,  icon: 'chart-pie'  };
export const formsRoutes = {  name: 'Forms',  to: '/guardian/forms',  exact: true,  icon: 'comments'};
export const announcementRoutes = {  name: 'Announcements',  to: '/guardian/announcements',  exact: true,  icon: ['fab', 'trello']};
export const profileRoutes = {  name: 'Profile',  to: '/guardian/profile',  icon: 'envelope-open'};



export default [
  myKidsRoutes,
  formsRoutes,
  announcementRoutes,
  profileRoutes
];
