import * as appconst from './service-constants';
import axios from 'axios';
import { IClassroomsBySchoolResponseModel } from '../models/dto-models';
import authStore from '../store/authstore/auth-store';



export class ClassroomService {

    public static GetClassroomsBySchool = async (): Promise<IClassroomsBySchoolResponseModel[]> => {
        const methname = `[GetClassroomsBySchool()] `;
        console.log(`${methname} invoked.`);

        return new Promise<IClassroomsBySchoolResponseModel[]>((resolve, reject) => {

            axios({
                method: 'GET',
                url: `${appconst.CLASSROOM_CLASSROOMSBYSCHOOL_URL}`,
                // headers: {
                //     Authorization : 'Bearer ' + `${authStore.getAuthToken()}`
                // }
            }).then((res) => {
                console.log(`${methname} resolving with `, res.data);
                resolve(res.data);
            }).catch(err => {
                console.error(`${methname}`, err);
                // throw err;
                reject(err);
            });

        });
    }
}