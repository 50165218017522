import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import SchoolInfo from '../components/school/school-info';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import ProductProvider from '../components/e-commerce/ProductProvider';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';

//const AppRoutes = loadable(() => import('./AppRoutes'));
const SchoolAdminRoutes = loadable(() => import('./AdminRoutes'));

const AppLayout = ({ location }) => {
  const { isFluid, isTopNav, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  console.log(`[AppLayout] `, location);

  useEffect(() => {
    //AppRoutes.preload();
    SchoolAdminRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {!isTopNav && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <>
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          <Switch>
            {/* <Route path="/staff" exact component={StaffSummaryList} /> */}
            {/* <Route path="/classroom" exact component={ClassRoomStatusSummary} /> */}
            {/* <Route path="/admin" component={SchoolAdminRoutes} /> */}
            {/* <AppRoutes /> */}
            <SchoolAdminRoutes />
          </Switch>
          {!isKanban && <Footer />}
        </div>
        <SidePanelModal path={location.pathname} />
      </ProductProvider>
      </>
    </div>
  );
};

AppLayout.propTypes = { location: PropTypes.object.isRequired };

export default AppLayout;
