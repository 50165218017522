
//export const BARE_BASE_URL='https://localhost:44326/';
export const BARE_BASE_URL='https://schoolqueue-beta.azurewebsites.net/';
export const BASE_URL=`${BARE_BASE_URL}api/`;
//export const BASE_URL='http://localhost:60215/api/';


export const LOGIN_URL = `${BASE_URL}Authenticate/login`;
export const SCHOOLSTAFF_GETSTAFFFORSCHOOLID_URL=`${BASE_URL}staff/getStaffForSchool/`;
export const STUDENTS_GETSTUDENTSBYSCHOOLID_URL=`${BASE_URL}student/getStudents/`;
export const STUDENTS_GETSTUDENTSBYGUARDIAN_URL=`${BASE_URL}guardian/getStudentsForGuardian/`;
export const GUARDIAN_GETGUARDIAN_BYSCHOOL_URL = `${BASE_URL}guardian/getGuardiansForSchool/`;
export const SCHOOL_SCHOOLINFO_URL = `${BASE_URL}schooladmin/getSchoolSummary`;
export const CLASSROOM_CLASSROOMSBYSCHOOL_URL = `${BASE_URL}classroom/getClassrooms`;
export const MEDIA_FILEUPLOAD_URL = `${BASE_URL}mediaasset/upload`;




// OLD URLs
export const SCHOOL_HUB_URL=`${BARE_BASE_URL}sqhub`;

export const ACTIVITY_GETALLACTIVITY_URL=`${BASE_URL}activity/getActivityLog`;

export const SCHOOL_GETSCHOOLS_URL=`${BASE_URL}school/getSchools`;
export const SCHOOL_GETSCHOOLBYID_URL=`${BASE_URL}school/getSchool/`;

export const GUARDIAN_GETGUARDIANS_URL=`${BASE_URL}guardian/getGuardians`;
export const GUARDIAN_GETGUARDIANBYID_URL=`${BASE_URL}guardian/getGuardian/`;
export const GUARDIAN_GETSTUDENTSFORGUARDIAN_URL=`${BASE_URL}guardian/getStudentsForGuardian/`;
export const GUARDIAN_GETGUARDIANBYPIN_URL=`${BASE_URL}guardian/getGuardianByAccountPin/`;
export const GUARDIAN_CREATEGUARDIAN_URL=`${BASE_URL}guardian/createGuardianAccount/`;

export const SCHOOLSTAFF_VALIDATELOGIN_URL=`${BASE_URL}schoolStaff/validateSchoolStaffLogin/`;
export const SCHOOLSTAFF_GETSCHOOLSTAFFBYSCHOOLID_URL=`${BASE_URL}schoolStaff/getSchoolStaffBySchoolId/`;

export const STUDENT_GETSTUDENTS_URL=`${BASE_URL}student/getStudentsBySchool/`;
export const REPORT_DAILYREPORT_URL=`${BASE_URL}report/getDailyReport/`; // append 'schoolId/date'
