import appDispatcher from "../app-dispatcher";
import { UserActionTypes } from "./user-store";
import { SchoolService } from "../../services/school-service";
import { StaffService } from "../../services/staff-service";
import { StudentService } from "../../services/student-service";
import { GuardianService } from "../../services/guardian-service";
import { ClassroomService } from "../../services/classroom-service";
import { IStaffBySchoolResponseModel, IStudentsBySchoolResponseModel, IGuardiansBySchoolResponseModel, IClassroomsBySchoolResponseModel, IStudentsByGuardianResponseModel } from "../../models/dto-models";
import { IStaffModel, IStudentModel, IGuardianModel, ISchoolInfoModel, IClassroomModel, IStudentForGuardianModel } from "../../models/store-models";



export async function performHydrateInMemUserStore() : Promise<void> {
    let methname = '[performHydrateInMemUserStore] ';

    console.log(`${methname} invoked.`);

    return new Promise((res, rej) => {
        
        res();
    });
}


export async function performGetStaffForSchool() : Promise<IStaffModel[]> {
    let methname = `[performGetStaffForSchool] `;
    
    console.log(`${methname} invoked.`);

    return StaffService.GetStaffForSchool().then((result: IStaffBySchoolResponseModel[]) => {
        let payload: IStaffModel[] = [];
        result.map((staff: IStaffBySchoolResponseModel) => {
            let s: IStaffModel = {
                email : staff?.email,
                firstName: staff?.firstName,
                id: staff?.id,
                lastName: staff?.lastName,
                phone: staff?.phone,
                fullName: `${staff?.firstName} ${staff?.lastName}`,
                isPinUsed: staff?.isPinUsed
            };
            payload.push(s);
        });

        appDispatcher.dispatch({
            type: UserActionTypes.STAFF_BY_SCHOOL,
            payload: payload
        });

        return payload;
    });
}


export async function performGetStudentsBySchool() : Promise<IStudentModel[]> {
    let methname = `[performGetStudentsBySchool] `;
    
    console.log(`${methname} invoked.`);

    return StudentService.GetStudentsBySchool().then((result: IStudentsBySchoolResponseModel[]) => {
        let payload: IStudentModel[] = [];
        result.map((student: IStudentsBySchoolResponseModel) => {
            let s: IStudentModel = {
                activeSeasonName: student.activeSeasonName,
                classRoomIdentifier: student.classRoomIdentifier,
                dateOfBirth: student.dateOfBirth,
                enrollmentId: student.enrollmentId,
                firstName: student.firstName,
                fullName: `${student.firstName} ${student.lastName}`,
                id: student.id,
                lastName: student.lastName,
                schoolId: student.schoolId,
                timeslotName: student.timeslotName
            };
            payload.push(s);
        });

        appDispatcher.dispatch({
            type: UserActionTypes.STAFF_BY_SCHOOL,
            payload: payload
        });

        return payload;
    });
}




export async function performGetStudentsByGuardian() : Promise<IStudentModel[]> {
    let methname = `[performGetStudentsByGuardian] `;
    
    console.log(`${methname} invoked.`);

    return StudentService.GetStudentsByGuardian().then((result: IStudentsByGuardianResponseModel[]) => {
        let payload: IStudentForGuardianModel[] = [];
        result.map((student: IStudentsByGuardianResponseModel) => {
            let s: IStudentForGuardianModel = {
                activeSeasonName: student.activeSeasonName,
                classRoomIdentifier: student.classRoomIdentifier,
                dateOfBirth: student.dateOfBirth,
                enrollmentId: student.enrollmentId,
                firstName: student.firstName,
                fullName: `${student.firstName} ${student.lastName}`,
                id: student.id,
                lastName: student.lastName,
                schoolId: student.schoolId,
                timeslotName: student.timeslotName,
                schoolName: student.schoolName,
                studentPictureUri: student.studentPicture
            };
            payload.push(s);
        });

        appDispatcher.dispatch({
            type: UserActionTypes.STUDENTS_BY_GUARDIAN,
            payload: payload
        });

        return payload;
    });
}






export async function performGetGuardiansBySchool() : Promise<IGuardianModel[]> {
    let methname = `[performGetGuardiansBySchool] `;
    
    console.log(`${methname} invoked.`);

    return GuardianService.GetGuardiansBySchool().then((result: IGuardiansBySchoolResponseModel[]) => {
        let payload: IGuardianModel[] = [];
        result.map((guardian: IGuardiansBySchoolResponseModel) => {
            let s: IGuardianModel = {
                firstName: guardian.firstName,
                id: guardian.id,
                lastName: guardian.lastName,
                email: guardian.email,
                phone: guardian.phone,
                fullName: `${guardian.firstName} ${guardian.lastName}`
            };
            payload.push(s);
        });

        appDispatcher.dispatch({
            type: UserActionTypes.STAFF_BY_SCHOOL,
            payload: payload
        });

        return payload;
    });
}

export async function performGetSchoolInfo() : Promise<ISchoolInfoModel> {
    let methname = `[performGetSchoolInfo] `;
    
    console.log(`${methname} invoked.`);

    return SchoolService.GetSchoolInfo().then((result: ISchoolInfoModel) => {
        
        let payload: ISchoolInfoModel = {
            activeSeasonDescription: result.activeSeasonDescription,
            activeSeasonName: result.activeSeasonName,
            contactPersonName: result.contactPersonName,
            displayName: result.displayName,
            id: result.id,
            phone: result.phone,
            schoolAddress: result.schoolAddress,
            schoolName: result.schoolName,
            activeSeasonId: result.activeSeasonId
        };

        appDispatcher.dispatch({
            type: UserActionTypes.SCHOOL_INFO,
            payload: payload
        });

        return payload;
    });
}


export async function performGetClassroomsBySchool() : Promise<IClassroomModel[]> {
    let methname = `[performGetClassroomsBySchool] `;
    
    console.log(`${methname} invoked.`);

    return ClassroomService.GetClassroomsBySchool().then((res: IClassroomsBySchoolResponseModel[]) => {
        
        let payload: IClassroomModel[] = [];
        res.map((s: IClassroomsBySchoolResponseModel) => {
            let cr: IClassroomModel = {
                activeSeasonId: s.activeSeasonId,
                activeSeasonName: s.activeSeasonName,
                classroomDescription: s.classroomDescription,
                classroomIdentifier : s.classroomIdentifier,
                id : s.id,
                schoolId : s.schoolId
            };
            payload.push(cr);
        });

        appDispatcher.dispatch({
            type: UserActionTypes.CLASSROOM_INFO,
            payload: payload
        });

        return payload;
    });
}