import axios from 'axios';
import authStore from '../store/authstore/auth-store';



const ServiceInterceptor = () => {

    // deregister any existing interceptors
    if (reqInt) axios.interceptors.request.eject(reqInt);
    if (resInt) axios.interceptors.request.eject(resInt);
    

    var reqInt = axios.interceptors.request.use((request) => {
        console.log(`[Interceptor - Outgoing request] headers before auth header`, request.headers);
        return request;
    }, (error) => {
            console.log(`[Interceptor - Request Error] `, error);
            return Promise.reject(error);
        }
    );



    var resInt = axios.interceptors.response.use((next) => {
        //console.log(`[Interceptor - Response Success] `, next);
        return Promise.resolve(next);
    }, (error) => {        
        if (error.response.status === 401) {
            console.log(`[Interceptor - Response - Auth Error] `, error.response.status);
        }
        console.log(`[Interceptor - Response Error] `, error);
        return Promise.reject(error);
        }
    );



    axios.defaults.headers.common['Authorization'] = `Bearer ${authStore.getAuthToken()}` // for all requests
    
}

export default ServiceInterceptor;