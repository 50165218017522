import appDispatcher from "../app-dispatcher";
import { AuthActionTypes } from "./auth-store";
import { ILoginInputModel, ILoginResponseModel } from "../../models/dto-models";
import { AuthService } from "../../services/auth-service";
import { ISchoolInfoModel } from "../../models/store-models";
import { UserActionTypes } from "../userstore/user-store";
import { performGetSchoolInfo } from "../userstore/user-actions";


export async function performHydrateInMemAuthStore() : Promise<void> {
    let methname = '[performHydrateInMemAuthStore] ';

    console.log(`${methname} invoked.`);

    return new Promise((res, rej) => {
        appDispatcher.dispatch({
            type: AuthActionTypes.HYDRATE_IN_MEM_AUTH_STORE,
        });
        appDispatcher.dispatch({
            type: UserActionTypes.HYDRATE_IN_MEM_USER_STORE,
        });
        res();
    });
}

export async function performLogInUser(userinfo: ILoginInputModel) : Promise<{redirectUrl: string, school: ISchoolInfoModel}> {
    let methname = `[performLogInUser] `;
    
    console.log(`${methname} invoked.`, userinfo);

    let loginResult: ILoginResponseModel;
    let schoolInfo: ISchoolInfoModel;

    try {
        loginResult = await AuthService.Login(userinfo);

        appDispatcher.dispatch({
            type: AuthActionTypes.LOGGED_IN,
            payload: loginResult
        });
    }
    catch(ex) {
        console.error(`${methname} Rejecting. Call Login failed with error: `, ex);
        return new Promise((res, rej) => rej(ex));
    }

    try {
        //schoolInfo = await performGetSchoolInfo();
    }
    catch(ex) {
        console.error(`${methname} Rejecting. Call to getSchoolInfo failed with error: `, ex);
        return new Promise((res, rej) => rej(ex));
    }

    return new Promise((resolve, reject) => {
        
        let ret = 'auth';
        if (loginResult.role === 'SchoolAdmin') {
            ret = '/admin';
        }
        else if (loginResult.role === 'SchoolStaff') {
            ret = '/staff';
        }
        else if (loginResult.role === 'Guardian') {
            ret = '/guardian';
        }
        console.log(`${methname} resolving with '${ret}'`);
        resolve({redirectUrl: ret, school: schoolInfo});
    });

}


export function performLoggedOutUser() : Promise<void> {
    return new Promise((resolve, reject) => {
        appDispatcher.dispatch({
            type: AuthActionTypes.LOGGED_OUT
        });
        appDispatcher.dispatch({
            type: UserActionTypes.LOGGED_OUT
        });
        resolve();
    });    
}