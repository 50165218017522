import { EventEmitter } from "events";
import appDispatcher from "../app-dispatcher";
import { IAuthStoreDataModel, IAuthUserModel } from "../../models/store-models";
import PersistDataService, { PersistDataServiceKeyNames } from "../../services/local-storage";
import { ILoginResponseModel } from "../../models/dto-models";
import { AppRolesEnum } from '../../constants/AppRolesEnum';


const AUTH_CHANGE = 'change';


export const AuthActionTypes = {
    HYDRATE_IN_MEM_AUTH_STORE: 'HYDRATE_IN_MEM_AUTH_STORE',
    LOGGED_IN: 'LOGGED_IN',
    LOGGED_OUT: 'LOGGED_OUT'
}



// ========================================
// Create an instance of the primary AuthStoreData
// Keep it private without exposing it outside
// so that it is not manipulated directly.
// It has to be manipulated via the exposed
// AuthStore instance only.
// ========================================
const _authStoreData: IAuthStoreDataModel = {
    isAuthenticated : false,
    user: {
        userName: ``,
        userDisplayName: ``,
        bearerToken: ``,    
        role: ``,
        schoolDisplayNames: []
    }
};
// ========================================



class AuthStore extends EventEmitter {

    addChangeListener(callback: any) {
        this.on(AUTH_CHANGE, callback);
    }

    removeChangeListener(callback: any) {
        this.removeListener(AUTH_CHANGE, callback);
    }

    emitChange() {
        console.log(`================`);
        console.log(`PERSISTING DATA: `, _authStoreData);
        console.log(`================`);
        PersistDataService.store(PersistDataServiceKeyNames.SQAuthStore, JSON.stringify(_authStoreData));
        this.emit(AUTH_CHANGE);
    }


    getStoreData(): IAuthStoreDataModel {
        return _authStoreData;
    }

    getIsLoggedIn() : boolean {
        return _authStoreData.isAuthenticated;
    }

    getAuthToken() : string {
        return _authStoreData?.user?.bearerToken;
    }

    getSchoolName(): string {
        return _authStoreData?.user?.schoolDisplayNames[0];
    }

    getUserRole() : AppRolesEnum {
        let methname = `[getUserRole] `;
        let retval = AppRolesEnum.None;
        if (!_authStoreData.isAuthenticated){
            console.log(`${methname} returning `, retval);
            return retval;
        }

        switch(_authStoreData?.user?.role) {
            case AppRolesEnum.SchoolAdmin:                
                retval = AppRolesEnum.SchoolAdmin;
                break;
            case AppRolesEnum.SchoolStaff:
                retval = AppRolesEnum.SchoolStaff;
                break;
            case AppRolesEnum.Guardian:
                retval = AppRolesEnum.Guardian;
                break;
            default:
                retval = AppRolesEnum.None;
                break;
        }
        
        console.log(`${methname} returning `, retval);
        return retval;
    }

}





// create an instance of the authstore
const authStore = new AuthStore();

// register reducer actions with the dispatcher
appDispatcher.register((action: any) => {

    const mname = `[AuthStore.appDispatcher.register] `;
    switch (action.type) {

        case AuthActionTypes.HYDRATE_IN_MEM_AUTH_STORE:
            let hydratedAuthStr = PersistDataService.retreive(PersistDataServiceKeyNames.SQAuthStore);
            let hydratedAuth: IAuthStoreDataModel = JSON.parse(hydratedAuthStr);

            _authStoreData.isAuthenticated = hydratedAuth?.isAuthenticated;
            _authStoreData.user = hydratedAuth?.user;

            authStore.emitChange();
            break;

        case AuthActionTypes.LOGGED_IN:
            console.log(`${mname} invoked with ${AuthActionTypes.LOGGED_IN}`, action.payload);

            let d: ILoginResponseModel = action.payload;
            _authStoreData.isAuthenticated = d.isSuccess;
            let au: IAuthUserModel = {
                bearerToken: d.bearerToken,
                role: d.role,
                schoolDisplayNames: d.schoolDisplayNames,
                userDisplayName: d.userDisplayName,
                userName: d.username
            };
            _authStoreData.user = au;

            //PersistDataService.store(PersistDataServiceKeyNames.SQAuthStore, JSON.stringify(_authStoreData));
            authStore.emitChange();
            break;
        case AuthActionTypes.LOGGED_OUT:
            console.log(`${mname} invoked with ${AuthActionTypes.LOGGED_OUT}`);
            
            _authStoreData.isAuthenticated = false;
            _authStoreData.user = null;

            authStore.emitChange();
            PersistDataService.clear(PersistDataServiceKeyNames.SQAuthStore);
            break;
        default:
            console.log(`${mname} invalid action type`, action.type);
            break;
    }
});

export default authStore;