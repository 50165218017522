import React, { useEffect } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useAuth } from './providers/auth-provider';
import ServiceInterceptor from './services/interceptors';
import withRedirect from './hoc/withRedirect';


export interface IProtectedRouteProps extends RouteProps { }

const ProtectedRoute: React.SFC<IProtectedRouteProps> = ({ component: MyComponent, ...rest } : IProtectedRouteProps) => {
    
    let methname = `[ProtectedRoute] `;

    const { authData, setAuthData } = useAuth();

    useEffect(() => {
        console.log(`[${methname}] useEffect(authData) invoked`);
        ServiceInterceptor();
    }, []);
    

    return (
        <Route {...rest} render = {
            props => {
                if (authData.isAuthenticated) {
                    return <MyComponent {...rest} {...props} />;
                } else {
                    return <Redirect to={
                        { 
                            // pathname: '/authentication/basic/login',
                            pathname: '/auth/login',
                            state: { from: props.location }
                        }
                    } />
                }
            } 
        } />
    )
}

export default ProtectedRoute;