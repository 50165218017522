import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import AppLayout from './AppLayout';
import ErrorLayout from './ErrorLayout';
import ProtectedRoute from '../protected-route';
import AuthLogin from '../components/school/auth-login';

import loadable from '@loadable/component';
import { performHydrateInMemAuthStore } from '../store/authstore/auth-actions';
import { performHydrateInMemUserStore } from '../store/userstore/user-actions';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const AdminLayout = loadable(() => import('./AdminLayout'));
const StaffLayout = loadable(() => import('./StaffLayout'));
const GuardianLayout = loadable(() => import('./GuardianLayout'));

const RootLayout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
    StaffLayout.preload();
    GuardianLayout.preload();

    // hydrate stores
    performHydrateInMemAuthStore();
    performHydrateInMemUserStore();

  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/auth" component={AuthBasicLayout} />
        {/* <Route path="/auth" component={AuthLogin} /> */}
        <Route path="/errors" component={ErrorLayout} />
        <ProtectedRoute path="/admin" component={AdminLayout} />
        <ProtectedRoute path="/staff" component={StaffLayout} />
        <ProtectedRoute path="/guardian" component={GuardianLayout} />
        <ProtectedRoute component={AppLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default RootLayout;
